import React from 'react'

const Terms = () => {
    return (
        <div className='bg-main '>
            <div className=' container py-5'>
                <h1 className='text-center'>Privacy Policy and Terms of Service</h1>

                <h2 className='my-3'>Privacy Policy</h2>

                <h4>1. Data We Collect</h4>
                <p>
                    <strong>Personal Information:</strong> We collect your full name, email address, and password during signup.<br />
                    <strong>Uploaded Files:</strong> Users upload background images, audio files, and other customization content for video creation.<br />
                    <strong>Payment Information:</strong> We use Stripe to process payments for premium features. We do not store payment details directly.
                </p>

                <h4>2. How We Use Your Data</h4>
                <p>
                    <strong>Email Address:</strong><br />
                    - For account verification and transactional emails.<br />
                    - To send video links created using our platform.<br />
                    - For payment processing notifications via Stripe.<br />
                    <strong>Uploaded Files:</strong> Uploaded files are used solely for video creation and are deleted immediately after the video is generated.<br />
                    <strong>Generated Videos:</strong> Video links are sent to the user’s email and deleted from our servers within 24 hours.
                </p>

                <h4>3. Data Storage</h4>
                <p>User information (e.g., name and email) is stored permanently on our systems to maintain account records and improve user experience.</p>

                <h4>4. Data Sharing</h4>
                <p>
                    We do not share your personal information with third parties, except:<br />
                    - <strong>Stripe:</strong> For payment processing.<br />
                    - As required by law or to comply with legal obligations.
                </p>

                <h4>5. Cookies and Tracking</h4>
                <p>
                    We do not use cookies or analytics to track user behavior. However, Stripe may use cookies for payment processing purposes. Please refer to Stripe’s Privacy Policy for more details.
                </p>

                <h4>6. Your Rights</h4>
                <p>You can request account deletion by contacting us at <a href="mailto:info@digitalmediabay.com">info@digitalmediabay.com</a>.</p>

                <h2 className='my-3'>Terms of Service</h2>

                <h4>1. Eligibility</h4>
                <p><strong>Anyone</strong> is allowed to use this platform regardless of age or location.</p>

                <h4>2. Uploaded Content</h4>
                <p>
                    Users can upload <strong>any content</strong> (images, audio, etc.), provided it does not violate the law or cause harm.<br />
                    We do not monitor uploads but reserve the right to act if content violates local laws.
                </p>

                <h4>3. Account Responsibility</h4>
                <p>
                    You are responsible for maintaining the confidentiality of your account and password.<br />
                    Notify us immediately if you suspect unauthorized access to your account.
                </p>

                <h4>4. Premium Subscription</h4>
                <p>
                    <strong>Watermark Removal:</strong> Available through a premium subscription at $5.99 per month.<br />
                    Payments are processed securely through Stripe.<br />
                    <strong>No Refunds:</strong> Payments are non-refundable, even in cases of dissatisfaction.
                </p>

                <h4>5. Limitation of Liability</h4>
                <p>
                    We promise <strong>24/7 uptime</strong> for our platform and services. In the rare case of downtime, we strive to resolve it immediately.
                </p>

                <h4>6. Misuse of Platform</h4>
                <p>
                    Users who attempt to exploit or harm the system will be <strong>permanently banned</strong>.<br />
                    Exploiting the system includes but is not limited to hacking attempts, fraud, or spamming.
                </p>

                <h4>7. File and Video Retention</h4>
                <p>
                    Uploaded files are deleted immediately after video creation.<br />
                    Generated videos are deleted from our servers within 24 hours.
                </p>

                <h4>8. Support</h4>
                <p>
                    We provide support exclusively through email. For any issues, reach out to us at <a href="mailto:info@digitalmediabay.com">info@digitalmediabay.com</a>.
                </p>

                <h4>9. Modifications to Terms</h4>
                <p>
                    We may update these Terms of Service and Privacy Policy at any time. Changes will be effective immediately upon posting on our website.
                </p>

                <h4>10. Contact</h4>
                <p>
                    For questions or support, contact us at: <a href="mailto:info@digitalmediabay.com">info@digitalmediabay.com</a>.
                </p>
            </div>
        </div>
    )
}

export default Terms