import React, { useContext, useEffect, useRef, useState } from 'react';
import './home.css';
import tinycolor from 'tinycolor2';
// import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.min.js';
import RegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions.min.js';
import wavesurfer from 'wavesurfer.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPause, faPlay, faRotate, faVolumeHigh, faVolumeMute } from '@fortawesome/free-solid-svg-icons'
import context from '../../context/context.js';
import Swal from 'sweetalert2';
import Loader from '../Loading/Loading.jsx';
import { Link } from 'react-router-dom';
import { ColorExtractor } from 'react-color-extractor'

const Home = () => {
  const MAX_VIDEO_SIZE_BYTES = 30 * 1024 * 1024; // Convert MB to bytes
  const MAX_IMAGE_SIZE_BYTES = 15 * 1024 * 1024; // Convert MB to bytes
  const a = useContext(context);
  const me = a.me;
  const loggedIn = a.loggedIn;
  const createVideo = a.createVideo
  const [backgroundImage, setbackgroundImage] = useState("none")
  const [backgroundVideo, setbackgroundVideo] = useState("none")
  const [diskImage, setdiskImage] = useState()
  const [backgroundSize, setbackgroundSize] = useState("square")
  const [tempbackgroundColor, settempbackgroundColor] = useState("#ff2a61")
  const [suggestedColors, setSuggestedColors] = useState()
  const [radio, setRadio] = useState(90);
  const [email, setemail] = useState("")
  const [ytLink, setytLink] = useState("")
  const [loading, setloading] = useState(false)
  const [audio, setaudio] = useState()
  const [watermark, setwatermark] = useState("true");
  const [backgroundType, setbackgroundType] = useState("image")
  useEffect(() => {
    if (loggedIn === "yes") {
      setemail(me.email);
    }
    if (me.tier === "Pro") {
      setwatermark("false")
    }
  }, [loggedIn]);
  const rotatingComponentRef = useRef(null);
  const [isFocused, setIsFocused] = useState(true);

  useEffect(() => {
    const handleFocus = () => {
      // setIsFocused(true);
      requestAnimationFrame(() => {
        // Reapply the animation when the window regains focus
        setTimeout(() => {
          if (rotatingComponentRef?.current?.style?.animation) {
            rotatingComponentRef.current.style.animation = 'rotate 10s linear infinite';
          }
        }, 100);

      });
    };

    const handleBlur = () => {
      setIsFocused(false);
      // Pause the animation when the window loses focus
      if (rotatingComponentRef?.current?.style?.animation) {
        rotatingComponentRef.current.style.animation = 'none';
      }
    };

    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);

    return () => {
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
    };
  }, []);


  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > MAX_IMAGE_SIZE_BYTES) {
        Swal.fire("Image file too large (15mb max)", "", "error");
        e.target.value = null;
      } else {
        const reader = new FileReader();
        reader.onloadend = () => {
          setdiskImage(reader.result)
          const fileName = file.name.length > 20 ? file.name.substring(0, 20) + "..." : file.name;
          document.getElementById("fileInputLabel").innerText = fileName
        };
        reader.readAsDataURL(file); // Read the file as a data URL
      }
    }
  };


  const handleAudioChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > MAX_VIDEO_SIZE_BYTES) {
        Swal.fire("Audio file too large (30mb max)", "", "error");
        e.target.value = null;
      } else {
        const reader = new FileReader();
        reader.onloadend = () => {
          developwavesurfer()
          setaudio(reader.result);
          const fileName = file.name.length > 20 ? file.name.substring(0, 20) + "..." : file.name;
          document.getElementById("audioInputLabel").innerText = fileName
          console.log(file)
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handlebackVideoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > MAX_VIDEO_SIZE_BYTES) {
        Swal.fire("Video file too large (30mb max)", "", "error");
        e.target.value = null;
      } else {
        const reader = new FileReader();
        reader.onloadend = () => {
          setbackgroundVideo(reader.result);
          const fileName = file.name.length > 20 ? file.name.substring(0, 20) + "..." : file.name;
          document.getElementById("VideoInputLabel").innerText = fileName
        };
        reader.readAsDataURL(file); // Read the file as a data URL
      }
    }
  };

  const handlebackImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > MAX_IMAGE_SIZE_BYTES) {
        Swal.fire("Image file too large (15mb max)", "", "error");
        e.target.value = null;
      } else {
        const reader = new FileReader();
        reader.onloadend = () => {
          setbackgroundImage(reader.result);
          const fileName = file.name.length > 20 ? file.name.substring(0, 20) + "..." : file.name;
          document.getElementById("ImageInputLabel").innerText = fileName
        };
        reader.readAsDataURL(file); // Read the file as a data URL
      }
    }
  };
  const [rotation, setRotation] = useState(0);
  const [color, setcolor] = useState("light");
  const [backgroundColor, setbackgroundColor] = useState('#ff2a61')

  useEffect(() => {
    const interval = setInterval(() => {
      setRotation(rotation => rotation + 0.4);
    }, 10);

    return () => clearInterval(interval);
  }, []);
  function ChangeLogoColor(colorHex) {
    // Remove hash if it exists
    colorHex = colorHex.replace(/^#/, '');
    const r = parseInt(colorHex.substring(0, 2), 16);
    const g = parseInt(colorHex.substring(2, 4), 16);
    const b = parseInt(colorHex.substring(4, 6), 16);
    // Calculate relative luminance (per ITU-R BT.709)
    const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    // Check if luminance is above a threshold to determine if the color is light
    if (luminance > 128) {
      setcolor("light")
    } else {
      setcolor("dark")
    }
  }

  useEffect(() => {
    if (backgroundColor) {
      ChangeLogoColor(backgroundColor);
      const parsedColor = tinycolor(backgroundColor);
      if (parsedColor.isValid()) {
        settempbackgroundColor(parsedColor.toHexString());
        setbackgroundColor(parsedColor.toHexString());
      }
    }
  }, [backgroundColor])
  const inputColorChangeHandler = (e) => {
    const inputValue = e.target.value;
    settempbackgroundColor(inputValue)
    try {
      // Attempt to parse the input color
      const parsedColor = tinycolor(inputValue);
      // Check if the color is valid
      if (parsedColor.isValid()) {
        // Convert the color to hexadecimal format
        const hexColor = parsedColor.toHexString();
        // Update the state with the hexadecimal color value
        setbackgroundColor(hexColor);
      } else {
        // Handle invalid color input
        console.error('Invalid color input:', inputValue);
      }
    } catch (error) {
      // Handle parsing errors
      console.error('Error parsing color:', error);
    }
  };

  // Audio Code
  const wavesurferRef = useRef(null);
  const timelineRef = useRef(null);
  const [start, setstart] = useState(0)
  const [end, setend] = useState(90)

  // fetch file url from the context

  // crate an instance of the wavesurfer
  const [wavesurferObj, setWavesurferObj] = useState();

  const [playing, setPlaying] = useState(false); // to keep track whether audio is currently playing or not
  const [volume, setVolume] = useState(1); // to control volume level of the audio. 0-mute, 1-max
  let zoom = 1; // to control the zoom level of the waveform
  const [duration, setDuration] = useState(0); // duration is used to set the default region of selection for trimming the audio

  // create the waveform inside the correct component
  const developwavesurfer = () => {
    if (wavesurferObj) {
      wavesurferObj.destroy()
      setWavesurferObj()
      // wavesurfer?.destroy()
    }
    setTimeout(() => {
      setWavesurferObj(
        wavesurfer.create({
          container: '#waveform',
          scrollParent: true,
          autoCenter: true,
          cursorColor: '#eb4363',
          loopSelection: true,
          waveColor: '#FFFFFF',
          progressColor: '#FFFFFF',
          height: 60,
          responsive: true,
          plugins: [
            RegionsPlugin.create({}),
          ],
        })
      )
    }, 10);

  }

  // once the file URL is ready, load the file to produce the waveform
  useEffect(() => {
    if (audio && wavesurferObj) {
      wavesurferObj.load(audio);
      console.log("audio Loaded")
    }
  }, [audio, wavesurferObj]);

  useEffect(() => {
    if (wavesurferObj) {
      console.log("Wavesurfer Started")
      // once the waveform is ready, play the audio
      wavesurferObj.on('ready', () => {
        wavesurferObj.play();
        wavesurferObj.enableDragSelection({}); // to select the region to be trimmed
        setDuration(Math.floor(wavesurferObj.getDuration())); // set the duration in local state
      });

      // once audio starts playing, set the state variable to true
      wavesurferObj.on('play', () => {
        setPlaying(true);
      });

      // once audio starts playing, set the state variable to false
      wavesurferObj.on('finish', () => {
        setPlaying(false);
      });

      // if multiple regions are created, then remove all the previous regions so that only 1 is present at any given time
      wavesurferObj.on('region-updated', (region) => {
        const regions = region.wavesurfer.regions.list;
        const keys = Object.keys(regions);
        const updatedStart = regions[keys[0]]?.start;
        const updatedEnd = regions[keys[0]]?.end;
        const regionDuration = updatedEnd - updatedStart;
        if (keys.length > 1) {
          // Remove additional regions (if any)
          regions[keys[1]].remove();
        }

        setstart(updatedStart);
        setend(regions[keys[0]]?.end);
      });

    }
  }, [wavesurferObj]);

  useEffect(() => {
    if (wavesurferObj) {
      var duration = wavesurferObj.getDuration()
      var playbackPosition;
      // Calculate the playback position as a fraction of the total duration
      if (duration === 0) {
        playbackPosition = start
      } else {
        playbackPosition = start / duration;
      }
      if (playbackPosition >= 0 && playbackPosition <= 1) {
        // Set the playback position to the specified start time
        wavesurferObj.seekTo(playbackPosition);
        // Start playback
        wavesurferObj.play();
      } else {
        // Handle the out-of-range error (display a message, log it, etc.)
        console.error("Invalid playback position. Must be a number between 0 and 1.", playbackPosition);
      }
      // Start playback
      wavesurferObj.play();
    }
  }, [start, wavesurferObj]);

  // set volume of the wavesurfer object, whenever volume variable in state is changed
  useEffect(() => {
    if (wavesurferObj) wavesurferObj.setVolume(volume);
  }, [volume, wavesurferObj]);

  // set zoom level of the wavesurfer object, whenever the zoom variable in state is changed
  useEffect(() => {
    if (wavesurferObj) wavesurferObj.zoom(zoom);
  }, [zoom, wavesurferObj]);

  // when the duration of the audio is available, set the length of the region depending on it, so as to not exceed the total lenght of the audio
  useEffect(() => {
    if (duration && wavesurferObj) {
      // add a region with default length
      wavesurferObj.addRegion({
        start: 0, // time in seconds
        end: radio, // time in seconds
        color: 'hsla(265, 100%, 86%, 0.4)', // color of the selected region, light hue of purple
        resize: false
      });
      setDuration()
    }
  }, [duration, wavesurferObj]);
  const handleRadioChange = (value) => {
    wavesurferObj.clearRegions();
    console.log(wavesurferObj)
    wavesurferObj.addRegion({
      start: 0, // time in seconds
      end: value, // time in seconds
      color: 'hsla(348, 73%, 57%, 0.4)', // color of the selected region, light hue of purple
      // maxLength: value
      resize: false // user cannot resize the region
    });
    setstart(0)
    setend(value)
    wavesurferObj.on('region-updated', (region) => {
      const regions = region.wavesurfer.regions.list;
      const keys = Object.keys(regions);
      const updatedStart = regions[keys[0]]?.start;
      const updatedEnd = regions[keys[0]]?.end;
      const regionDuration = updatedEnd - updatedStart;
      if (keys.length > 1) {
        // Remove additional regions (if any)
        regions[keys[1]].remove();
      }
      setstart(updatedStart);
      setend(regions[keys[0]]?.end);
    });
  }

  const handlePlayPause = (e) => {
    wavesurferObj.playPause();
    setPlaying(!playing);
  };

  const handleReload = (e) => {
    // stop will return the audio to 0s, then play it again
    wavesurferObj.stop();
    wavesurferObj.play();
    setPlaying(true); // to toggle the play/pause button icon
  };

  const handleVolumeSlider = (e) => {
    setVolume(e.target.value);
  };
  function isUrlFromDomain(url) {
    try {
        // Attempt to create a new URL object from the given URL string
        const parsedUrl = new URL(url);

        // Get the hostname (domain) from the parsed URL
        const urlDomain = parsedUrl.hostname;
        console.log(urlDomain)

        // Check if the parsed domain matches the specified domain
        return urlDomain === "youtu.be"||urlDomain==="www.youtube.com";
    } catch (error) {
        // Handle invalid URL error
        console.error("Invalid URL:", error);
        return false;
    }
}

  const submitsound = async () => {
    Swal.update({ buttonsStyling: { backgroundColor: "#ff2a61" } })
    if (!diskImage) {
      return Swal.fire("Please fill in all the required fields", "", "error")
    }
    if (backgroundType==="ytLink"&&!isUrlFromDomain(ytLink)) {
      return Swal.fire("Invalid Url", "", "error")
    }
    if (!audio) {
      return Swal.fire("Please fill in all the required fields", "", "error")
    }
    if (!email) {
      return Swal.fire("Please fill in all the required fields", "", "error")
    }
    if (backgroundType === "video" && backgroundVideo === "none") {
      return Swal.fire("Please fill in all the required fields", "", "error")
    }
    setloading(true)
    wavesurferObj.stop()
    await createVideo({ytLink, email, start, end, audio, backgroundColor, backgroundImage, backgroundVideo, backgroundType, backgroundSize, diskImage, watermark })
    setaudio();
    wavesurferObj.destroy();
    setWavesurferObj();
    setdiskImage();
    setbackgroundImage("none");
    setbackgroundVideo("none");
    setbackgroundType("image");
    setloading(false);
  };
  const handleToggle = (e) => {
    setbackgroundType(e.target.value);
  };
  return (
    <div className='bg-main min-90'>
      <div className="main">
        {loading ?
          <Loader /> :
          <>
            <div className="left">
              <form onSubmit={(e) => {
                e.preventDefault()
              }}>
                 <p class="text-heading-popup1 ">{me?.tier!=="Pro"?"Sign up and subscribe for $5.99/month to remove all video watermarks.":"Transform your audio content into engaging social media videos."}</p>
                <div className="d-flex flex-column gp">
                  <label className="topLable">Disc Image</label>
                  <div className="upload-btn-wrapper">
                    {/* Use a label to associate the button with the file input */}
                    <label id='fileInputLabel' className="btnn" htmlFor="fileInput">
                      Choose Image File
                      <img src="/Assets/img.svg" style={{ objectFit: 'cover' }} alt="Icon" />
                    </label>
                    {/* Hidden file input */}
                    <input
                      id="fileInput"
                      type="file"
                      className="d-none"
                      accept=".jpg, .jpeg, .gif, .png"
                      name='bgimage'
                      onChange={handleImageChange}
                    />
                  </div>
                  <div className="litleText">Select your disk image (jpg, gif, png only below 15mb).</div>
                </div>
                <div className="d-flex flex-column gp cust-dropdown">
                  <div className="d-flex ">
                    <div className="topLable">Dimensions</div>
                  </div>
                  <select
                    className="slec"
                    value={backgroundSize}
                    onChange={(e) => {
                      setbackgroundSize(e.target.value);
                    }}
                  >
                    <option className='text-dark' value="square">Square</option>
                    <option className='text-dark' value="reel">Reel/Story</option>
                  </select>
                  <div className="litleText">
                    Resize your video into a square or reel/story.
                  </div>
                </div>
                <div className="d-flex flex-column gp">
                  <label className="topLable">Background Color</label>
                  <div className="coolmain" style={suggestedColors ? { width: "100%", display: "flex" } : { display: "none" }}>
                    {suggestedColors && (
                      <div className="colors" style={{ width: "100%", display: "flex" }}>
                        {suggestedColors.map((color, index) => (
                          <span
                            key={index}
                            style={{
                              backgroundColor: color,
                              width: `${100 / suggestedColors.length}%`,
                              padding: '1px',
                              border: "1px solid transparent",
                              cursor: "pointer"
                            }}
                            onClick={() => {
                              setbackgroundColor(color);
                            }}
                          ></span>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="d-flex align-items-center gap-2 inp-btnn">
                    <input className='custom-color-input' type='color' value={backgroundColor} onChange={e => setbackgroundColor(e.target.value)} />
                    <input className="colorInput" value={tempbackgroundColor} onChange={inputColorChangeHandler} />
                  </div>
                  <div className="litleText">Select an image and we'll provide color suggestions.</div>
                </div>
                <div className='my-2'>
                  <label className="topLable mb-1">Background</label>

                  <div className="d-flex gap-2 flex-wrap">

                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="backgroundType"
                        id="imageRadio"
                        value="image"
                        checked={backgroundType === 'image'}
                        onChange={handleToggle}
                      />
                      <label className="form-check-label" htmlFor="imageRadio">
                        Image
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="backgroundType"
                        id="videoRadio"
                        value="video"
                        checked={backgroundType === 'video'}
                        onChange={handleToggle}
                      />
                      <label className="form-check-label" htmlFor="videoRadio">
                        Video
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="backgroundType"
                        value="ytLink"
                        checked={backgroundType === 'ytLink'}
                        onChange={handleToggle}
                        id='ytRadio'
                      />
                      <label className="form-check-label" htmlFor="ytRadio">
                         YouTube Link
                      </label>
                    </div>

                  </div>
                  <ColorExtractor
                    src={diskImage}
                    getColors={colors => {
                      setSuggestedColors(colors.slice(0, 8))
                      setbackgroundColor(colors[0])
                    }}
                  />
                </div>
                {backgroundType === 'image' ?
                  <div className="d-flex flex-column gp">
                    <label className="topLable">Background Image</label>
                    <div className="upload-btn-wrapper">
                      <label id='ImageInputLabel' className="btnn" htmlFor="ImageInput">
                        Choose Image File
                        <img src="/Assets/img.svg" style={{ objectFit: 'cover' }} alt="Icon" />
                      </label>
                      <input
                        id="ImageInput"
                        type="file"
                        className="d-none"
                        accept=".jpg, .jpeg, .gif"
                        name='bgimage2'
                        onChange={handlebackImageChange}
                      />
                    </div>
                    <div className="litleText">Select your background image (jpg, gif only below 15mb).</div>
                  </div> :backgroundType==="video"?(
                  <div className="d-flex flex-column gp">
                    <label className="topLable">Background Video</label>
                    <div className="upload-btn-wrapper">
                      <label id='VideoInputLabel' className="btnn" htmlFor="VideoInput">
                        Choose Video File
                        <img src="/Assets/img.svg" style={{ objectFit: 'cover' }} alt="Icon" />
                      </label>
                      <input
                        id="VideoInput"
                        type="file"
                        className="d-none"
                        accept=".mov, .mp4"
                        name='bgimage2'
                        onChange={handlebackVideoChange}
                      />
                    </div>
                    <div className="litleText">Select your background video (mp4, mov below 30mb).</div>
                  </div>): <div className="d-flex flex-column gp">
                  <label className="topLable">YouTube Link</label>
                  <input
                    className="bottom-input"
                    placeholder="YouTube Link"
                    name='ytLink'
                    value={ytLink}
                    onChange={(e) => {
                      setytLink(e.target.value);
                    }}
                  />
                  <div className="litleText">Enter the YouTube video link (time stamp is supported).</div>
                </div>
                }
                <div className="d-flex flex-column gp cust-dropdown">
                  <div className="d-flex justify-content-between">
                    <div className="topLable">Watermark</div>
                    <Link to="/premium" style={{ textDecoration: "none" }}>
                      <div
                        className="pro"
                        style={{ textTransform: "capitalize" }}
                      >
                        Premium
                      </div>
                    </Link>
                  </div>
                  <select
                    disabled={me.tier !== "Pro"}
                    className="slec"
                    value={watermark}
                    onChange={(e) => {
                      setwatermark(e.target.value);
                    }}
                  >
                    <option className='text-dark' defaultChecked value="true">On</option>
                    <option className='text-dark' value="false">Off</option>
                  </select>
                  <div className="litleText">
                    Sign up and subscribe to Premium to remove watermarks.
                  </div>
                </div>
                <div className="d-flex flex-column gp">
                  <label className="topLable">Audio</label>
                  <div className="upload-btn-wrapper">
                    {/* Use a label to associate the button with the file input */}
                    <label id='audioInputLabel' className="btnn" htmlFor="audioInput">
                      Choose Audio File
                      <img src="/Assets/img.svg" style={{ objectFit: 'cover' }} alt="Icon" />
                    </label>
                    {/* Hidden file input */}
                    <input
                      id="audioInput"
                      type="file"
                      className="d-none"
                      accept="audio/mp3"
                      name='audiofile'
                      onChange={handleAudioChange}
                    />
                  </div>
                  <div className="litleText">Select your audio (mp3 only below 30mb).</div>
                  {
                    audio ? <>
                      <section className='waveform-container'>
                        <div ref={wavesurferRef} id='waveform' />
                        {/* <div ref={timelineRef} id='wave-timeline' /> */}
                        <div className='all-controls d-flex gap-2 mt-3'>
                          <div className='left-container'>
                            <button
                              style={{ all: "unset" }}
                              title='play/pause'
                              className='controls'
                              onClick={handlePlayPause}>
                              {playing ? (
                                <FontAwesomeIcon icon={faPause} style={{ cursor: "pointer", paddingRight: "10px" }} />
                              ) : (
                                <FontAwesomeIcon icon={faPlay} style={{ cursor: "pointer", paddingRight: "10px" }} />
                              )}
                            </button>
                            <button
                              title='reload'
                              style={{ all: "unset" }}
                              className='controls'
                              onClick={handleReload}>
                              <FontAwesomeIcon icon={faRotate} style={{ cursor: "pointer", paddingRight: "10px" }} />
                            </button>
                          </div>
                          <div className='right-container'>
                            <div className='volume-slide-container'>
                              {volume > 0 ? (
                                <FontAwesomeIcon icon={faVolumeHigh} style={{ paddingRight: "10px" }} />
                              ) : (
                                <FontAwesomeIcon icon={faVolumeMute} style={{ paddingRight: "10px" }} />
                              )}
                              <input
                                type='range'
                                min='0'
                                max='1'
                                step='0.05'
                                value={volume}
                                onChange={handleVolumeSlider}
                                className='slider volume-slider'
                              />
                            </div>
                          </div>
                        </div>
                      </section>
                      {/* Radio Buttons */}
                      <div className="d-flex justify-content-between radiobtns mb-2">
                        <div className="d-flex justify-content-center align-items-center">
                          <input
                            type="radio"
                            id="age1"
                            name="age"
                            onChange={() => {
                              setRadio(90);
                              handleRadioChange(90)
                            }}
                            checked={radio === 90 ? true : false}
                          />
                          <label
                            htmlFor="age1"
                            className='radioLabel'
                            style={{ color: "white", marginLeft: "5px" }}
                          >
                            Reel (90 sec)
                          </label>
                        </div>
                        <br className="disp1" />
                        <div className="d-flex justify-content-center align-items-center">
                          <input
                            type="radio"
                            id="age2"
                            name="age"
                            value="60"
                            onChange={() => {
                              setRadio(60);
                              handleRadioChange(60)
                            }}
                            checked={radio === 60 ? true : false}
                          />
                          <label
                            htmlFor="age2"
                            className='radioLabel'
                            style={{ color: "white", marginLeft: "5px" }}
                          >
                            Story (60 sec)
                          </label>
                        </div>
                        <br className="disp1" />
                        <div className="d-flex justify-content-center align-items-center">
                          <input
                            type="radio"
                            id="age3"
                            name="age"
                            value="100"
                            onChange={() => {
                              setRadio(180);
                              handleRadioChange(180)
                            }}
                            checked={radio === 180 ? true : false}
                          />
                          <label
                            htmlFor="age3"
                            className='radioLabel'
                            style={{ color: "white", marginLeft: "5px" }}
                          >
                            General (180 sec)
                          </label>
                        </div>
                        <br className="disp1" />
                        <br className="disp1" />
                      </div>
                    </> : ""
                  }

                </div>
                <div className="d-flex flex-column gp">
                  <label className="topLable">Email</label>
                  <input
                    className="bottom-input"
                    // placeholder="Enter Your Email"
                    name='email'
                    value={email}
                    onChange={(e) => {
                      setemail(e.target.value);
                    }}
                  />
                  <div className="litleText">You will be emailed your video (please check junk).</div>
                </div>
                <button className="bottom-button fw-bold mb-3" type='button' onClick={submitsound}>
                  Submit
                </button>
              </form>
            </div>
            <div className="right d-flex flex-column">
            <p class="text-heading-popup square">{me?.tier!=="Pro"?"Sign up and subscribe for $5.99/month to remove all video watermarks.":"Transform your audio content into engaging social media videos."}</p>
              {
                backgroundType === "image" ? <div className={backgroundSize} style={{ position: "relative", backgroundColor, backgroundImage: `url(${backgroundImage})`, backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat" }}>
                  {
                    watermark === "true" ?
                      <div className="watermarktop"><img style={{ filter: `${color === "light" ? "invert(1)" : ""}` }} width={50} src="https://res.cloudinary.com/df7gif899/image/upload/v1700244643/FixedImages/grid_landscape_qkmazx.png" alt="Logo" /></div>
                      : ""
                  }
                  <div className="rotating-component" ref={rotatingComponentRef} style={{
                    width: '270px',
                    height: '270px',
                    borderRadius: '50%',
                    // transform: `rotate(${rotation}deg)`,
                    // transition: 'transform 1s ease', // Smooth rotation effect
                    backgroundImage: `url(${diskImage ? diskImage : '/Assets/DEFAULT.jpg'})`,
                    animation: isFocused ? 'rotate 10s linear infinite' : 'none',
                    backgroundSize: 'cover',
                  }} ></div>
                  {
                    watermark === "true" ?
                      <div className="watermarkbottom"><img width={150} style={{ filter: `${color === "light" ? "invert(1)" : ""}` }} src="https://res.cloudinary.com/df7gif899/image/upload/v1700244623/FixedImages/grid_landscape_axkq4b.png" alt="Digital Media Bay" /></div>
                      : ""
                  }
                </div> : <div className={backgroundSize} style={{ position: "relative", backgroundColor: "white", backgroundImage: `url(${backgroundSize}video.jpg)`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                  {
                    watermark === "true" ?
                      <div className="watermarktop"><img style={{ filter: "invert(1)" }} width={50} src="https://res.cloudinary.com/df7gif899/image/upload/v1700244643/FixedImages/grid_landscape_qkmazx.png" alt="Logo" /></div>
                      : ""
                  }
                  <div className="rotating-component"
                    ref={rotatingComponentRef} style={{
                      width: '270px',
                      height: '270px',
                      borderRadius: '50%',
                      animation: isFocused ? 'rotate 10s linear infinite' : 'none',
                      backgroundImage: `url(${diskImage ? diskImage : '/Assets/DEFAULT.jpg'})`,
                      backgroundSize: 'cover',
                    }} ></div>
                  {
                    watermark === "true" ?
                      <div className="watermarkbottom"><img width={150} style={{ filter: "invert(1)" }} src="https://res.cloudinary.com/df7gif899/image/upload/v1700244623/FixedImages/grid_landscape_axkq4b.png" alt="Digital Media Bay" /></div>
                      : ""
                  }
                </div>
              }
            </div>
          </>
        }
      </div>
    </div>
  );
};

export default Home;
