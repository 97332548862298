import React, { useContext, useState } from "react";
import "../styles/SignIn.css";
import Form from "react-bootstrap/Form";
import Swal from "sweetalert2";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Link } from "react-router-dom";
import context from "../context/context";
import Loader from "../Components/Loading/Loading";
export default function SignIn() {
  const a = useContext(context);
  const login = a.login;
  const [state, setState] = useState({ email: "", password: "" });
  const [loading, setloading] = useState(false)
  const [show, setShow] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if(state.email===""||state.password===""){
      return Swal.fire("Please fill in all the required fields", "", "error");
    }
    setloading(true);
    const response = await login(state)
    if (response.success) {
        window.location.href = "/"
    } else {
      Swal.fire(response.message, "", "error");
    }
    setloading(false)
  };
  return (
    <div className="containerSignup pt-3">
      {loading ? <Loader /> :
        <div className="SignUpBox">
          <h2 className="SignUpHeading">Sign In</h2>
          <Form.Label htmlFor="inputPassword5">
            <div className="EmailText">Email</div>
          </Form.Label>
          <Form.Control
            type="text"
            id="inputPassword5"
            onChange={(e) => {
              setState({ ...state, email: e.target.value });
            }}
            aria-describedby="passwordHelpBlock"
          // placeholder="Enter Your Email"
          />
          <div className="PasswordBtn position-relative">
            <Form.Label htmlFor="inputPassword5">
              <div className="EmailText">Password</div>
            </Form.Label>
            <Form.Control
              type={show == true ? "text" : "password"}
              id="inputPassword5"
              onChange={(e) => {
                setState({ ...state, password: e.target.value });
              }}
              aria-describedby="passwordHelpBlock"
            // placeholder="Enter Your Password"
            />
            {show == true ? (
              <span
                className="eyee"
                onClick={() => {
                  setShow(false);
                }}
              >
                <AiOutlineEyeInvisible />
              </span>
            ) : (
              <span
                className="eyee"
                onClick={() => {
                  setShow(true);
                }}
              >
                <AiOutlineEye />
              </span>
            )}
            <span className="PasswordDetails">
              Forgot your password?{" "}
              <span className="Reset">
                <Link
                  to="/forgotPass"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Reset Password
                </Link>
              </span>
            </span>
          </div>
          <div className="bottom-button">
            <div
              className="fw-bold"
              // className="buttonText"
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Sign In
            </div>
          </div>
        </div>
      }
    </div>
  );
}
