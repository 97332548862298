import React, { useContext, useEffect, useState } from 'react';
import styles from "../styles/thankyou.module.css";
import context from '../context/context.js';
import Loader from '../Components/Loading/Loading';

const Page = () => {
    const a = useContext(context);
    const bemember = a.bemember;
    const checkoutloading = a.checkoutloading;
    const setcheckoutloading = a.setcheckoutloading;
    const thankyoudata=a.thankyoudata;
    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const token = searchParams.get('token');
        if (token && token !== '') {
            bemember({ token });
        } else {
            setcheckoutloading(false)
        }
    }, []);
    return (
        <div className={"bg-main min-90"}>
            {
                checkoutloading ? <Loader /> :
                    <>
                        <div className={styles.information}>
                            <h1>Thank you!</h1>
                            <div style={{ width: "70px", color: "#06429e" }}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 thankyou">
                                    <path strokeLinecap="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                </svg>
                            </div>
                            <div>
                                Payment Successful
                            </div>
                            <div>
                                Order:{thankyoudata.invoiceNumber}
                            </div>
                            <div style={{ marginBottom: "2rem", fontSize: "30px", marginTop: "2rem" }}>
                                {thankyoudata.title}
                            </div>

                        </div>
                        <div className={styles.invoice}>
                            <div className={styles.row}>
                                <div className={styles["row-logo"]}>
                                    <img src={"/product.png"} width={30} height={30} />
                                </div>
                                <div style={{ borderBottom: "1px solid #8a89898f" }} className={styles["row-content"]}>
                                    <div style={{ display: "inline-block" }}>Premium Membership Register – Payment</div>
                                    <div style={{ float: "right" }}>{thankyoudata.price} $</div>
                                </div>
                            </div>
                            <div className={styles.row2}>
                                <div className={styles["row-content"]}>
                                    <div style={{ marginLeft: "50px", display: "inline-block" }}>Total</div>
                                    <div style={{ float: "right" }}>{thankyoudata.price} $</div>
                                </div>
                            </div>
                        </div>
                        <a style={{
                            color: "white",
                            textDecoration: "none",
                            display: "block",
                            margin: "auto",
                            width: "max-content",
                            marginTop: "1rem"
                        }} href="/">Start Creating</a>
                        </>
            }

        </div>
    );
};

export default Page;
