import React, { useContext, useState } from "react";
import "./pro.css";
import context from "../context/context";
import Loader from "../Components/Loading/Loading";
const Pro = () => {
  const a = useContext(context);
  const createcheckoutsession = a.createcheckoutsession;
  const [loading, setloading] = useState(false)
  const loggedIn = a.loggedIn
  const createSession = async () => {
    setloading(true)
    await createcheckoutsession()
    setloading(false)
  }
  return (
    <div cla style={{ minHeight: "90vh" }} className="bg-main d-flex align-items-center justify-content-center">
      {
        loading ? <Loader /> :<div
          style={{ backgroundColor: "#2e2e2e", width: "100%" }}
          className="gridd d-flex justify-content-center align-items-center mt-3 griddd"
        >
          <div className="stripeCard">
            <h2 style={{fontWeight:"800"}}>Premium</h2>
            <div className="PayDollar fw-bold">$5.99 USD/Month</div>
            <div className="payText">
              Upgrading to a Premium subscription allows you to remove the watermark logos.
            </div>
            <div onClick={createSession} className="bottom-button fw-bold">
              Subscribe
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default Pro;
