import React, { useContext, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import styles from "../styles/account.css";
import 'bootstrap/dist/css/bootstrap.css'
import context from '../context/context';
import Loader from '../Components/Loading/Loading';

const Page = () => {
    const a=useContext(context);
    const getmypayments=a.getmypayments;
    const payments=a.payments;
    const checkoutloading=a.checkoutloading
    useEffect(() => {
      getmypayments()
    }, [])
    const formatDate = (dateStr) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const date = new Date(dateStr);
        return date.toLocaleDateString('en-US', options);
      };
    return (
        <div className='accountmain min-90 pt-4'>
            <div className="container">
               
                 {
                    checkoutloading?<Loader/>: 
                    <>
                     <h2 className='fw-bold' style={{ textAlign: "center" }}>Payments</h2>
                <div className={"table-responsive"}>
                      <table style={{"--bs-table-bg": "transparent"}}  class="table table-dark">
                    <thead>
                        <tr>
                            <th scope="col">Invoice</th>
                            <th scope="col">Date</th>
                            <th scope="col">Card</th>
                            <th scope="col">Status</th>
                            <th scope="col">Email</th>
                            <th scope="col">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                       {
                        payments?.map((item,index)=>{
                            return  <tr key={index}>
                            <th scope="row" className={styles.subrow}>
                                <div >
                                <span >{item.invoiceNumber}</span> <br />
                                <a style={{ color: "white" }} href="/">Premium Membership</a>
                                </div>
                            </th>
                            <td><b>{formatDate(item.created)}</b></td>
                            <td>{item.amount}</td>
                            <td className={styles.statusrow}><div>{item.status}</div></td>
                            <td>{item.customerEmail}</td>
                            <td>{item.amount}</td>
                        </tr>
                        })
                       }
                    </tbody>
                </table>
                </div>
                </>
                 }

            </div>
        </div>
    )
}

export default Page