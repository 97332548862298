import React, { useContext, useEffect, useState } from "react";
import "./pro.css";
import context from "../context/context";
import Loader from "../Components/Loading/Loading";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
const Pro = () => {
    const a = useContext(context);
    const createcheckoutsession = a.createcheckoutsession;
    const verifyAccountFunc = a.verifyAccount;
    const [loading, setloading] = useState(false)
    const createSession = async () => {
        setloading(true)
        await createcheckoutsession()
        setloading(false)
    }
    const location = useLocation();

    const getTokenFromUrl = () => {
        const queryParams = new URLSearchParams(location.search);
        return queryParams.get("token");
    };

    const verifyAccount = async () => {
        setloading(true)
        const token = getTokenFromUrl();
        const response = await verifyAccountFunc({ token });
        if (!response.success) {
            Swal.fire(response.message, "", "success").then(() => {
                window.location.href = "/"
            });
        } else {
            setloading(false)
        }
    }
    useEffect(() => {
        verifyAccount();
    }, [])

    return (
        <div cla style={{ minHeight: "90vh" }} className="bg-main d-flex align-items-center justify-content-center">
            {
                loading ? <Loader /> : <div
                    style={{ backgroundColor: "#2e2e2e", width: "100%" }}
                    className="gridd d-flex justify-content-center align-items-center mt-3 griddd"
                >
                    <div className="stripeCard">
                        <div className="verificationData mb-4">
                            <h2 style={{ fontWeight: "800" }}>Verified</h2>
                            <div className="payText">
                                Thank you. Your account has been verified.
                            </div>
                        </div>
                        <h2 style={{ fontWeight: "800" }}>Premium</h2>
                        <div className="PayDollar fw-bold">$5.99 USD/Month</div>
                        <div className="payText">
                            Upgrading to a Premium subscription allows you to remove the watermark logos.
                        </div>
                        <div onClick={createSession} className="bottom-button fw-bold">
                            Subscribe
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default Pro;
