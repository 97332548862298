import React, { useState } from 'react'
import "../styles/SignIn.css";
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
const Page = () => {
    const location = useLocation();
    const [loading, setloading] = useState(false)
    const queryParams = new URLSearchParams(location.search);
    const downloadUrl = queryParams.get('url');


    const handleDownload = () => {
        setloading(true)
        if (downloadUrl) {
            fetch(downloadUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/octet-stream',
                },
            }).then(response => response.blob())
                .then(blob => {
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Digitalmediabay.mp4'); // specify the desired filename here
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                })
                .catch(error => {
                    Swal.fire('Download failed', error.message, "error");
                }).finally(() => {
                    setloading(false)
                });
        } else {
            Swal.fire("Download URL not provided in the query parameters!", "", "error");
        }
    };
    return (
        <div className='containerSignup pt-3'>
            <div style={{ flexDirection: "column" }} className='d-flex align-items-center justify-content-center mx-3'>
                <h2 className='SignUpHeading'>Your video is ready!</h2>
                <p className='mb-0' style={{ maxWidth: "25rem", textAlign: "center" }}>Your video is now ready for download and can be saved to your desktop or
                    mobile device. Please note, this link will expire in 24 hours.</p>
                <div onClick={() => handleDownload()} className="bottom-button">
                    <button disabled={loading} style={{ all: "unset" }} className="fw-bold">
                        {loading ? "Loading..." : "Download"}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Page