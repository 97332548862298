"use client"
import React, { useEffect, useState } from 'react';
import context from './context.js';
import moment from 'moment';
import Swal from 'sweetalert2';
// const host = 'http://localhost:5001'
const host = process.env.REACT_APP_HOST

const State = (props) => {
  const [loading, setloading] = useState(false);
  const [loggedIn, setloggedIn] = useState('Loading');
  const [me, setme] = useState({});
  const [checkoutloading, setcheckoutloading] = useState(true)
  const [payments, setpayments] = useState([])
  const [subscription, setsubscription] = useState([]);
  const [thankyoudata, setthankyoudata] = useState({});
  const login = async (data) => {
    const response = await fetch(`${host}/api/user/login`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
    const json = await response.json();
    if (json.success) {
      localStorage.setItem('secret-login-token', json.token)
      return { success: true, message: "none" }
    } else {
      return { success: false, message: json.message }
    }
  }

  const resetpassSend = async (data) => {
    const response = await fetch(`${host}/api/user/forgetpass`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
    const json = await response.json();
    if (json.success) {
      return { success: true, message: "none" }
    } else {
      return { success: false, message: json.message }
    }
  }
  const resetpassVerify = async (data) => {
    const response = await fetch(`${host}/api/user/resetpassword`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET",
        "Content-Type": "application/json",
        "Authentication": data.token
      },
      body: JSON.stringify(data)
    })
    const json = await response.json();
    if (json.success) {
      localStorage.setItem('secret-login-token', json.token)
      localStorage.removeItem('otp-login-token')
      return { success: true, message: "none" }
    } else {
      return { success: false, message: json.message }
    }
  }

  const register = async (data) => {
    const response = await fetch(`${host}/api/user/register`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
    const json = await response.json();
    if (json.success) {
      return { success: true, message: "none" }
    } else {
      return { success: false, message: json.message }
    }
  }
  const verifyAccount = async (data) => {
    const response = await fetch(`${host}/api/user/verifyAccount`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET",
        "Content-Type": "application/json",
        "Authentication":data.token
      },
      body: JSON.stringify(data)
    })
    const json = await response.json();
    if (json.success) {
      localStorage.setItem('secret-login-token', json.token)
      return { success: true, message: "none" }
    } else {
      return { success: false, message: json.message }
    }
  }

  const sendotp = async (data) => {
    const response = await fetch(`${host}/api/user/sendotp`, {
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
    const json = await response.json();
    if (json.success) {
      localStorage.setItem("otp-login-token", json.token);
      return true
    } else {
      Swal.fire(json.message, "", "error")
      return false
    }
  }

  // const resetpasswordotp = async (data) => {
  //   const response = await fetch(`${host}/api/user/resetpasswordotp`, {
  //     method: "post",
  //     headers: {
  //       "Access-Control-Allow-Origin": "*",
  //       "Access-Control-Allow-Methods": "GET",
  //       "Content-Type": "application/json"
  //     },
  //     body: JSON.stringify(data)
  //   })
  //   const json = await response.json();
  //   if (json.success) {
  //     localStorage.setItem("otp-login-token", json.token);
  //     return true
  //   } else {
  //     Swal.fire(json.message, "", "error")
  //     return false
  //   }
  // }
  // const resetpass = async (data) => {
  //   const response = await fetch(`${host}/api/user/resetpassword`, {
  //     method: "post",
  //     headers: {
  //       "Access-Control-Allow-Origin": "*",
  //       "Access-Control-Allow-Methods": "GET",
  //       "Content-Type": "application/json",
  //       "Authentication": localStorage.getItem('otp-login-token')
  //     },
  //     body: JSON.stringify(data)
  //   })
  //   const json = await response.json();
  //   if (json.success) {
  //     localStorage.removeItem("otp-login-token");
  //     Swal.fire("Successfully updated", "", "success");
  //     return true
  //   } else {
  //     Swal.fire(json.message, "", "error")
  //     return false
  //   }
  // }
  const getme = async () => {
    setloading(true);
    if (localStorage.getItem("secret-login-token")) {
      const response = await fetch(`${host}/api/user/getme`, {
        method: "get",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET",
          "Content-Type": "application/json",
          "Authentication": localStorage.getItem('secret-login-token')
        }
      });

      const json = await response.json();
      if (json.success) {
        setme(json.user)
        setloggedIn("yes")
      } else {
        Swal.fire(json.message, "", "error")
        localStorage.clear()
        setloggedIn("no")
      }
    } else {
      setloggedIn("no")
    }
    setloading(false)
  };
  const createcheckoutsession = async () => {
    setcheckoutloading(true)
    try {
      if (localStorage.getItem("secret-login-token")) {
        const response = await fetch(`${host}/api/user/create-checkout-session`, {
          method: "post",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET",
            "Content-Type": "application/json",
            "Authentication": localStorage.getItem('secret-login-token')
          }
        });
        const json = await response.json();
        if (json.success) {
          window.location.href = json.session
        } else {
          Swal.fire(`${json.message}`, "", "error")
        }
      } else {
        Swal.fire("Please login", "", "error")
      }
    } catch (error) {
      Swal.fire(`${error.message}`, "", "error")
    }
    setcheckoutloading(false)
  };
  const cancelMembership = async () => {
    try {
      if (localStorage.getItem("secret-login-token")) {
        const response = await fetch(`${host}/api/user/cancelmembership`, {
          method: "post",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET",
            "Content-Type": "application/json",
            "Authentication": localStorage.getItem('secret-login-token')
          }
        });
        const json = await response.json();
        if (json.success) {
          Swal.fire(`Plan Cancelled`, "You will have access until the conclusion of your billing period.", "success")
        } else {
          Swal.fire(`${json.message}`, "", "error")
        }
      } else {
        Swal.fire("Login to continue...", "", "error")
      }
    } catch (error) {
      Swal.fire(`${error.message}`, "", "error")
    }
  };
  const updateme = async (data) => {
    setloading(true)
    const response = await fetch(`${host}/api/user/updateme`, {
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET",
        "Content-Type": "application/json",
        "Authentication": localStorage.getItem('secret-login-token')
      },
      body: JSON.stringify(data)
    })
    const json = await response.json();
    if (json?.success) {
      window.location.href = "/account"
    } else {
      setloading(false)
      Swal.fire(json.message, "", "error")
    }
  };
  const getmypayments = async (data) => {
    setcheckoutloading(true)
    const response = await fetch(`${host}/api/user/mypayments`, {
      method: "get",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET",
        "Content-Type": "application/json",
        "Authentication": localStorage.getItem('secret-login-token')
      }
    })
    const json = await response.json();
    if (json.success) {
      setpayments(json.payments)
    } else {
      Swal.fire(json.message, "", "error")
    }
    setcheckoutloading(false)
  }
  const getmysubscription = async (data) => {
    setcheckoutloading(true)
    const response = await fetch(`${host}/api/user/mysubscription`, {
      method: "get",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET",
        "Content-Type": "application/json",
        "Authentication": localStorage.getItem('secret-login-token')
      }
    })
    const json = await response.json();
    if (json.success) {
      setsubscription(json.subscription)
    } else {
      Swal.fire(json.message, "", "error")
    }
    setcheckoutloading(false)
  }

  const bemember = async (data) => {
    setcheckoutloading(true)
    try {
      const response = await fetch(`${host}/api/user/bemember`, {
        method: "post",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET",
          "Content-Type": "application/json",
          "Authentication": localStorage.getItem('secret-login-token')
        },
        body: JSON.stringify(data)
      })
      const json = await response.json();
      if (json.success) {
        setthankyoudata(json.membership)
      } else {
        if (json.message === "Token Already Used") {
          window.location.href = "/account"
        }
      }
    } catch (error) {
      Swal.fire(`${error.message}`, "", "error")
    }
    setcheckoutloading(false)
  };
  const createVideo = async (data) => {
    try {
      const response =await fetch(`${host}/api/user/createVideo`, {
        method: "post",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET",
          "Content-Type": "application/json",
          "Authentication": localStorage.getItem('secret-login-token')
        },
        body: JSON.stringify(data)
      })
      Swal.fire(``, "Digital Media Bay is processing your video; color/image backgrounds arrive in 3-5 mins, video/YouTube backgrounds in 10-15 mins. Please check your inbox and junk folders.", "success").then(()=>{
        window.location.reload()
      })
      // const json = await response.json();
      // if (json.success) {
      //   Swal.fire(`${json.message}`, "", "success").then(()=>{
      //     window.location.reload()
      //   })
      // } else {
      //   Swal.fire(`${json.message}`, "", "error")
      // }
    } catch (error) {
      Swal.fire(`${error.message}`, "", "error")
    }
  };

  const contactsubmit = async (data) => {
    setloading(true)

    const response = await fetch(`${host}/api/user/contact`, {
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET",
        "Content-Type": "application/json",
        "Authentication": localStorage.getItem('secret-login-token')
      },
      body: JSON.stringify(data)
    })
    const json = await response.json();
    if (json.success) {
      Swal.fire('Your Message is sent successfully!', "", "success")
    } else {
      Swal.fire(json.message, "", "error")
    }
    setloading(false)
  }
  return (
    <context.Provider value={{
    thankyoudata, createcheckoutsession, cancelMembership,resetpassSend,resetpassVerify,verifyAccount,
      contactsubmit, setcheckoutloading, payments,createVideo,
      getmypayments, getmysubscription, subscription, checkoutloading, bemember, updateme,
      me, getme, loading, setloading, sendotp, register, login, loggedIn, setloggedIn
    }}>
      {props.children}
    </context.Provider>
  )
}

export default State
