import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import "bootstrap/dist/css/bootstrap.min.css";

import { GoTriangleRight } from "react-icons/go";
import "./FAQ.css";

function AlwaysOpenExample() {

  const [activeItem, setActiveItem] = useState(null);

  const handleAccordionToggle = (eventKey) => {
    if (activeItem === eventKey) {
      setActiveItem(null);
    } else {
      setActiveItem(eventKey);
    }
  };

  const isItemOpen = (eventKey) => activeItem === eventKey;

  
  return (
    <div className="ffff py-3">
      <div className="Accordion">
        <h2 className="AccordionHeader">FAQ</h2>

        <Accordion>
          <Accordion.Item
            eventKey="0"
            style={{
              borderTopLeftRadius: "0.5em",
              borderTopRightRadius: "0.5em",
            }}
          >
            <Accordion.Header
              onClick={() => handleAccordionToggle("0")}
              className={isItemOpen("0") ? "AccordionOpen" : ""}
              style={{
                borderTopLeftRadius: "0.5em",
                borderTopRightRadius: "0.5em",
              }}
            >
              <div className="AccordionQuestion">
                <span className="IconTriangle">
                  <GoTriangleRight
                    className={`Triangle ${isItemOpen("0") ? "Open" : ""}`}
                  />
                </span>
                What size should the background and disc image be?
              </div>
            </Accordion.Header>

            <Accordion.Body>
              <div className="AccordionAnswer">
                The system supports both square (1082 pixels x 1082 pixels) and reel/story (1080 pixels x 1920 pixels) sizes, tailored for your social media platforms. Your image will be automatically resized for optimal formatting.
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header
              onClick={() => handleAccordionToggle("1")}
              className={isItemOpen("1") ? "AccordionOpen" : ""}
            >
              <div className="AccordionQuestion">
                <span className="IconTriangle">
                  <GoTriangleRight
                    className={`Triangle ${isItemOpen("1") ? "Open" : ""}`}
                  />
                </span>
                What is the time limit for each video?
              </div>
            </Accordion.Header>

            <Accordion.Body>
              <div className="AccordionAnswer">
                Each video has a time limit of 3 minutes. However, please be aware that the current time limits for social media are 90 seconds for reels and 60 seconds for stories. We will adjust our limits accordingly as social media platforms update their restrictions.
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header
              onClick={() => handleAccordionToggle("2")}
              className={isItemOpen("2") ? "AccordionOpen" : ""}
            >
              <div className="AccordionQuestion">
                <span className="IconTriangle">
                  <GoTriangleRight
                    className={`Triangle ${isItemOpen("2") ? "Open" : ""}`}
                  />
                </span>
                Which file can be uploaded to the disk or background?
              </div>
            </Accordion.Header>

            <Accordion.Body>
              <div className="AccordionAnswer">
              The system supports JPGs, GIFs, PNGs, .MP4s (for video backgrounds), and YouTube URLs (users can specify a start time/time stamp by right-clicking on the YouTube player). All other formats will be rejected. 
                </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="9">
            <Accordion.Header
              onClick={() => handleAccordionToggle("9")}
              className={isItemOpen("9") ? "AccordionOpen" : ""}
            >
              <div className="AccordionQuestion">
                <span className="IconTriangle">
                  <GoTriangleRight
                    className={`Triangle ${isItemOpen("9") ? "Open" : ""}`}
                  />
                </span>
                How do video backgrounds work?
              </div>
            </Accordion.Header>

            <Accordion.Body>
              <div className="AccordionAnswer">
              Please note that although there are no previews available for your video or YouTube background, your uploaded video or YouTube link will be seamlessly integrated into your final emailed video.
                </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="10">
            <Accordion.Header
              onClick={() => handleAccordionToggle("10")}
              className={isItemOpen("10") ? "AccordionOpen" : ""}
            >
              <div className="AccordionQuestion">
                <span className="IconTriangle">
                  <GoTriangleRight
                    className={`Triangle ${isItemOpen("10") ? "Open" : ""}`}
                  />
                </span>
                What are the upload size limits?
              </div>
            </Accordion.Header>

            <Accordion.Body>
              <div className="AccordionAnswer">
                The file size limit for both audio and video backgrounds is set at 30MB.</div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header
              onClick={() => handleAccordionToggle("3")}
              className={isItemOpen("3") ? "AccordionOpen" : ""}
            >
              <div className="AccordionQuestion">
                <span className="IconTriangle">
                  <GoTriangleRight
                    className={`Triangle ${isItemOpen("3") ? "Open" : ""}`}
                  />
                </span>
                How will I receive my video and in which format?
              </div>
            </Accordion.Header>

            <Accordion.Body>
              <div className="AccordionAnswer">
              You will be emailed a link to your video. To download, click the link, open in your browser, then right-click and select "Save As." When using mobile devices, the video will be saved in the "Files" folder for iPhone or "My Files" or "File Manager" on Android. Please note, the emailed video link expires in 4 hours.</div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="11">
            <Accordion.Header
              onClick={() => handleAccordionToggle("11")}
              className={isItemOpen("11") ? "AccordionOpen" : ""}
            >
              <div className="AccordionQuestion">
                <span className="IconTriangle">
                  <GoTriangleRight
                    className={`Triangle ${isItemOpen("11") ? "Open" : ""}`}
                  />
                </span>
                What is the estimated timeframe for receiving my video?
              </div>
            </Accordion.Header>

            <Accordion.Body>
              <div className="AccordionAnswer">
                Typically, videos with image backgrounds are delivered within approximately 5 minutes, while videos with video or YouTube backgrounds may take up to 15 minutes for delivery. Please be patient as your video is being processed and will be delivered to you soon. Additionally, we advise checking your junk folder to ensure receipt.
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="12">
            <Accordion.Header
              onClick={() => handleAccordionToggle("12")}
              className={isItemOpen("12") ? "AccordionOpen" : ""}
            >
              <div className="AccordionQuestion">
                <span className="IconTriangle">
                  <GoTriangleRight
                    className={`Triangle ${isItemOpen("12") ? "Open" : ""}`}
                  />
                </span>
                How many videos can I create?
              </div>
            </Accordion.Header>

            <Accordion.Body>
              <div className="AccordionAnswer">
              On both premium and free plans, you have the freedom to create an unlimited number of videos. However, upgrading to a Premium subscription allows you to remove the watermark logos.
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header
              onClick={() => handleAccordionToggle("4")}
              className={isItemOpen("4") ? "AccordionOpen" : ""}
            >
              <div className="AccordionQuestion">
                <span className="IconTriangle">
                  <GoTriangleRight
                    className={`Triangle ${isItemOpen("4") ? "Open" : ""}`}
                  />
                </span>
                What's included in the Premium subscription?
              </div>
            </Accordion.Header>

            <Accordion.Body>
              <div className="AccordionAnswer">
              Once you've signed up, you have the option to upgrade to a Premium subscription. The Premium subscription allows you to remove the watermark logos and is billed at $5.99 USD per month.
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header
              onClick={() => handleAccordionToggle("5")}
              className={isItemOpen("5") ? "AccordionOpen" : ""}
            >
              <div className="AccordionQuestion">
                <span className="IconTriangle">
                  <GoTriangleRight
                    className={`Triangle ${isItemOpen("5") ? "Open" : ""}`}
                  />
                </span>
                How can I pay?
              </div>
            </Accordion.Header>

            <Accordion.Body>
              <div className="AccordionAnswer">
                We currently accept credit card as payment powered by Stripe.
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6">
            <Accordion.Header
              onClick={() => handleAccordionToggle("6")}
              className={isItemOpen("6") ? "AccordionOpen" : ""}
            >
              <div className="AccordionQuestion">
                <span className="IconTriangle">
                  <GoTriangleRight
                    className={`Triangle ${isItemOpen("6") ? "Open" : ""}`}
                  />
                </span>
                How do I cancel my Premium subscription?
              </div>
            </Accordion.Header>

            <Accordion.Body>
              <div className="AccordionAnswer">
              Your Premium subscription can be cancelled at any time by simply visiting the Account tab and selecting "Cancel Subscription". You will have access to Premium subscription features until your current billing period expires.
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="7">
            <Accordion.Header
              onClick={() => handleAccordionToggle("7")}
              className={isItemOpen("7") ? "AccordionOpen" : ""}
            >
              <div className="AccordionQuestion">
                <span className="IconTriangle">
                  <GoTriangleRight
                    className={`Triangle ${isItemOpen("7") ? "Open" : ""}`}
                  />
                </span>
                Are there password requirements?
              </div>
            </Accordion.Header>

            <Accordion.Body>
              <div className="AccordionAnswer">
                For security reasons, your password must contain: <br />- 8 or
                more characters <br /> - 1 uppercase letter <br />- 1 number
                <br /> - 1 special character
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="8">
            <Accordion.Header
              onClick={() => handleAccordionToggle("8")}
              className={isItemOpen("8") ? "AccordionOpen" : ""}
            >
              <div className="AccordionQuestion">
                <span className="IconTriangle">
                  <GoTriangleRight
                    className={`Triangle ${isItemOpen("8") ? "Open" : ""}`}
                  />
                </span>
                I didn't receive the email with my video. What should I do?
              </div>
            </Accordion.Header>

            <Accordion.Body>
              <div className="AccordionAnswer">
               If you've waited over 20 minutes for any type of video and haven't received it, we recommend simply creating a new one. If you've already checked your junk folder and the problem persists, please reach out to our support team for assistance at <a href="mailto:info@digitalmediabay.com" className="fw-bold" style={{ textDecoration: "underline", color: "white" }}>info@digitalmediabay.com</a> with a detailed description of the problem.
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
}

export default AlwaysOpenExample;
