"use client"
import React, { useContext, useEffect } from 'react'
import styles from "../styles/account.css";
import 'bootstrap/dist/css/bootstrap.css'
import context from '../context/context';
import Loader from '../Components/Loading/Loading';
import { Link } from 'react-router-dom';

const Page = () => {
  const a = useContext(context);
  const getmysubscription = a.getmysubscription;
  const subscription = a.subscription;
  const checkoutloading = a.checkoutloading;
  const createcheckoutsession = a.createcheckoutsession;
  const cancelpro = a.cancelMembership
  const me = a.me;
  useEffect(() => {
    getmysubscription()
  }, []);
  const cancelMembership = async () => {
    const confirmation = window.confirm("Your membership will be cancelled at current billing period end.\nAre you sure you want to cancel your membership?");

    if (confirmation) {
      await cancelpro()
      document.getElementById("cancelbtn").disabled=true
      console.log("Membership canceled successfully.");
    } else {
      // User clicked Cancel in the confirmation dialog
      console.log("Membership cancellation aborted.");
    }
  }
  const RestartMembership=async()=>{
      await createcheckoutsession()
  }

  const formatDate = (dateStr) => {
    if(dateStr){
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(dateStr);
    return date.toLocaleDateString('en-US', options);
    }else{
      return 'N/A';
    }
  };
  return (
    <div className='accountmain subscription min-90 pt-4'>
      <div className="container ">
       
        {checkoutloading ? <Loader /> :
        <>
         <h2 className='fw-bold' style={{ textAlign: "center" }}>Account</h2>
         <div style={{ background: "transparent", color: "white", border: "1px solid white", float: "right" }} className="btn my-2">
           <Link style={{ color: "white", textDecoration: "none" }} to="/payments">
             View Payments
           </Link>
         </div>
         <div className={`table table-responsive`}>
          <table style={{"--bs-table-bg": "transparent"}} class="table table-dark">
            <thead>
              <tr>
                <th scope="col">Subscription</th>
                <th scope="col">Status</th>
                <th scope="col">Dates</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row" className={styles.subrow}>
                  <div >
                    <a style={{ color: "white" }} href="/"><b>Premium Membership</b></a> <br />
                    <span>{subscription ? subscription.stripe_subscription_id : "Free Access"}</span>
                  </div>

                </th>
                <td className={styles.statusrow}><div>{subscription ? subscription.status : "Active"}</div></td>
                <td><b>{formatDate(subscription?.current_period_end)}</b></td>
                <td><button id='cancelbtn' disabled={me.cancelled} onClick={cancelMembership} style={{ background: "transparent", color: "white", border: "1px solid white" }} className="btn">
                  Cancel Plan
                </button>
                {
                  me.cancelled?<button onClick={RestartMembership} style={{ background: "transparent", color: "white", border: "1px solid white" }} className="btn m-2">
                  Restart Plan
                </button>:""
                }
                </td>
              </tr>
            </tbody>
          </table>

        </div>
        </>}
      </div>
    </div>
  )
}

export default Page;