import React, { useContext, useEffect } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import context from '../../context/context.js';
import "./navbar.css"
const Navbar = () => {
    const a = useContext(context);
    const getme = a.getme;
    const me = a.me
    const loggedIn = a.loggedIn;
    // Import Javascript
    useEffect(() => {
        getme()
    }, []);
    function truncateString(inputString) {
        if (typeof inputString === 'string') {
            if (inputString.length > 12) {
                return inputString.slice(0, 12) + '...';
            } else {
                return inputString;
            }
        } else {
            return inputString;
        }
    }
    return (
        <nav className="navbar navbar-expand-lg bg-body-tertiary">
            <div className="container-fluid">
                <div className="navbar-brand">
                    <img
                        src="/Assets/DMB-LOGO.png"
                        width="150"
                        onClick={() => { window.location.href = "/" }}
                        className="d-inline-block align-top pointer"
                        alt="Your Logo"
                    />
                    <img
                        src="/logos/FB.png"
                        width="30"
                        height="30"
                        onClick={() => { window.open("https://www.facebook.com/thedigitalmediabay/", "_blank") }}
                        className="d-inline-block align-top ml-2 pointer"
                        alt="Facebook Logo"
                    />
                    <img
                        src="/logos/IG.png"
                        width="30"
                        height="30"
                        onClick={() => { window.open("https://www.instagram.com/digitalmediabay/", "_blank") }}
                        className="d-inline-block align-top ml-2 pointer"
                        alt="Instagram Logo"
                    />
                    <img
                        src="/logos/TIKTOK.png"
                        width="30"
                        height="30"
                        onClick={() => { window.open("https://www.tiktok.com/@digitalmediabay", "_blank") }}
                        className="d-inline-block align-top ml-2 pointer"
                        alt="Tiktok Logo"
                    />
                </div>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse nav-flex-end" role="search" id="navbarNavDropdown">
                    <ul className="navbar-nav ml-auto mx-3-mobile" style={
                        {
                            // marginLeft: "auto",
                            // marginRight: "1%"
                        }}>
                        <li className="nav-item">
                            <a className="nav-link fw-bold" href={`${me?.tier === "Pro" ? "/account" : "/premium"}`}>
                                {me?.tier === "Pro" ? "Account" : (me?.tier==="Free"&&"Premium")} 
                            </a>
                        </li>
                        {
                            loggedIn === "no" ? <>
                                <li className="nav-item">
                                    <a className="nav-link fw-bold" href="/signin">
                                        Sign In
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link fw-bold" href="/signup">
                                        Sign Up
                                    </a>
                                </li>
                            </>
                                : <li className="nav-item">
                                    <a className="nav-link fw-bold" onClick={() => {
                                        localStorage.clear();
                                        window.location.reload()
                                    }} href="/">
                                        Logout
                                    </a>
                                </li>
                        }
                        <li className="nav-item">
                            <a className="nav-link fw-bold" href="/faq">
                                FAQ
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link fw-bold" href="mailto:info@digitalmediabay.com">
                                Contact
                            </a>
                        </li>

                    </ul>
                    {/* {
                        loggedIn === "yes" ?
                            <div className={`accnav`}>
                                <img src={'/avatar.png'} width={40} height={40} alt='Avatar' />
                                <div style={{ display: 'flex', flexDirection: "column" }}>
                                    <div>{me?.name}</div>
                                    <small className={"email"}>{truncateString(me?.email)}</small>
                                </div>
                            </div> : ""
                    } */}

                </div>
            </div>
        </nav>
    );
};

export default Navbar;
